import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import TimeAgo from 'javascript-time-ago';
import ReactCanvasConfetti from 'react-canvas-confetti';
import allLocales from 'javascript-time-ago/load-all-locales';
import logo from './leaderboard_100.png';
import coins from './coins.svg';
import './App.css';

const translations = require('./translations');
const rewards = require('./rewards');

export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.youRef = React.createRef();
        const params = new URLSearchParams(window.location.search);
        var tLanguage = params.get('l') ? params.get('l').toUpperCase() : 'EN';
        document.documentElement.lang = tLanguage.toLowerCase();
        this.timeAgo = new TimeAgo(tLanguage.toLowerCase());
        this.state = {
            users: [],
            timestamp: 0,
            end: "",
            updated: "",
            user: "",
            rank: 0,
            reward: 0,
            fire: false,
            language: tLanguage
        }
    }

    componentDidMount() {
        const params = new URLSearchParams(window.location.search);

        var date = new Date(Math.floor(Date.now(Date.UTC()) / (1000 * 60 * 10)) * (1000 * 60 * 10));
        var timestamp = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

        fetch("https://api.make-money.one/leaderboard?d=" + Date.now())
        .then(res => res.json())
        .then(
            (users) => {
                if(!(users.items && users.items.length > 0)) {
                  setTimeout(() => {
                    window.location.reload(false);
                  }, 60000);
                } else {
                  var tUser = "";
                  var tRank = 0;
                  var tReward = 0;
                  if(params.get('u')) {
                    for(var i = 0; i < users.items.length; i++) {
                      if(users.items[i][0] === params.get('u')) {
                        tUser = params.get('u');
                        tRank = i + 1;
                        tReward = rewards[i];
                      }
                    }
                  }
                  if(tUser && tRank && tReward) {
                    this.setState({ users: users.items, timestamp, end: this.timeAgo.format(users.end), updated: this.timeAgo.format(users.updated), user: tUser, rank: tRank, reward: tReward, confetti: true });
                    var highestRank = localStorage.getItem(date.getUTCFullYear() + '-' + date.getUTCMonth() + '-' +  date.getUTCDate());
                    if(highestRank === null || parseInt(highestRank, 10) > tRank) {
                      this.setState({fire: true});
                    }
                    localStorage.setItem(date.getUTCFullYear() + '-' + date.getUTCMonth() + '-' +  date.getUTCDate(), tRank);
                  } else {
                    this.setState({ users: users.items, timestamp, end: this.timeAgo.format(users.end), updated: this.timeAgo.format(users.updated) });
                  }
                  this.interval = setInterval(() => {
                    date = new Date(Math.floor(Date.now(Date.UTC()) / (1000 * 60 * 10)) * (1000 * 60 * 10));
                    timestamp = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
                    if(timestamp !== this.state.timestamp) {
                      window.location.reload(false);
                    } else {
                      this.setState({ end: this.timeAgo.format(users.end), updated: this.timeAgo.format(users.updated) });
                    }
                  }, 60000);
              }
            }
        )
    }

    componentWillUnmount(){
      if(this.interval) {
        clearInterval(this.interval);
      }
    }

    render() {
        return (
          <div className="App">
            <a href="https://makemoneynow.page.link/?link=https://make-money.one&apn=com.make.money.now.big.cash.reward.app.paid.surveys.earn.money.mini.job&ibi=com.tvtwo.rewards&isi=1478553956&efr=1"><img src={logo} className="App-logo" alt="Daily Leaderboard" /></a>
            {this.state.users.length === 0 && (
              <TailSpin
                height="80"
                width="80"
                color="white"
                radius="1"
                visible={true}
                wrapperStyle={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  alignItems: 'center',
                  justifyContent: 'center'
               }}
              />
            )}
            {this.state.users.length > 0 && (
              <div>
                <div className="User-status" onClick={() => {
                  if(this.youRef && this.youRef.current) {
                    this.youRef.current.scrollIntoView({
                        behavior: 'auto',
                        block: 'center',
                        inline: 'center'
                    });
                }}}>
                  {this.state.user && (
                    <div>
                      <p><b>{translations[this.state.language]['your_ranking']}<br /></b>{this.state.rank}</p>
                      <p><b>{translations[this.state.language]['your_reward']}</b><br /><img className="App-coins" src={coins} alt="Credits" />&nbsp;{this.state.reward.toLocaleString()}</p>
                    </div>
                  )}
                  <p><b>{translations[this.state.language]['ends']}</b><br />{this.state.end}</p>
                  <hr className="App-border" />
                  {this.state.user && (<p className="App-instructions">{translations[this.state.language]['instructions']}</p>)}
                  {!this.state.user && (<p className="App-instructions">{translations[this.state.language]['new_user'].replace('%s', ((this.state.users[this.state.users.length - 1][1]) + 1).toLocaleString())}</p>)}
			            <p className="App-updated">{translations[this.state.language]['last_updated'] + ' ' + this.state.updated}</p>
                </div>
                <table>
                  <thead>
                      <tr>
                          <th>#</th>
                          <th>{translations[this.state.language]['user']}</th>
                          <th>{translations[this.state.language]['earned']}</th>
                          <th>{translations[this.state.language]['reward']}</th>
                      </tr>
                  </thead>
                  <tbody>
                      {this.state.users.map((user, index) =>
                          <tr key={index + 1}>
                              <td>{(index + 1).toLocaleString()}</td>
                              {index + 1 === this.state.rank && (<td><p className="User-you" ref={this.youRef}>{translations[this.state.language]['you']}</p></td>)}
                              {index + 1 !== this.state.rank && (<td>{user[0]}</td>)}
                              <td><img className="App-coins" src={coins} alt="Credits" />&nbsp;{user[1].toLocaleString()}</td>
                              <td><img className="App-coins" src={coins} alt="Credits" />&nbsp;{rewards[index].toLocaleString()}</td>
                          </tr>
                      )}
                  </tbody>
                </table>
              </div>
            )}
            <ReactCanvasConfetti
              className="canvas"
              particleCount={(1001 - this.state.rank)}
              style={{
                pointerEvents: 'none',
                position: 'absolute',
                height: '100%',
                width: '100%',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                alignItems: 'center',
                justifyContent: 'center'
             }}
              fire={this.state.fire}
              zIndex={-1}
            />
        </div>);
    }
}
